export const programCs = {
  programs: {
    toolTips: {
      edit: "Upravit produkt {program}",
      delete: "Odstranit produkt {program}",
      showEshopProduct: "Zobrazit navázaný produkt eshopu",
    },
    noBasicTrainings: "Skupina nema navázané žádné základní programy",
    editTitle: "Upravit produkt",
    newTitle: "Nový produkt",
    addProgram: "Přidat produkt",
    errors: {
      notFound: "Produkt id {id} nebyl nalezen",
      notYourRole: "Nemáte práva zobrazit produkt id {id}",
      nameExists: "Produkt s nazvem `{name}` již existuje",
      commonGET_PROGRAM_BY_ID: "Chyba při získávání dat o produktu",
      commonDELETE_PROGRAM_BY_ID: "Chyba při odstraňování produktu",
      commonDELETE_PROGRAMS: "Nepodařilo se odstranit produkt",
      cantGetPrograms: "Chyba při získávání dat o produktech",
    },
    form: {
      name: "Název",
      type: "Typ programu",
      name_client: "Název pro klienta",
      nrOfWeeks: "Délka programu",
      basicPrograms: "Navázané programy",
      programGroups: "Nadřazený program",
      weeks: "týdnů",
      wcProduct: "Eshop produkt",
      company: "Firma",
      specializations: "Specializace",
      trainings: "Tréninky",
      trainingTemplates: "Šablony tréninku",
      programChanged: "Produkt byl upraven",
      programChangedFail: "Nepodařilo se upravit produkt",
      programAdded: "Nový produkt byl vytvořen",
      programAddFail: "Nepodařilo se vytvořit nový produkt",
    },
    types: {
      grouped: "Programová skupina",
      basic: "Základní",
    },
    table: {
      header: {
        name: "Název",
        type: "Typ programu",
        basicPrograms: "Navázané programy",
        programGroups: "Nadřazený program",
        name_client: "Název pro klienta",
        nrOfWeeks: "Délka programu",
        company: "Firma",
        specializations: "Specializace",
        actions: "Akce",
      },

      deleteProgramQuestion:
        "Opravdu chcete odstranit produkt <strong>{program}</strong>?",
      searchPrograms: "Hledat produkty",
      programDeleted: "Produkt {program} byl odstraněn",
      programsDeleted: "Produkty byly odstraněny",
      noRecords: "Nebyly nalezeny žádné produkty",
    },
  },
};
