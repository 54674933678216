import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-header border-0 cursor-pointer" }, [
      _createElementVNode("div", { class: "card-title m-0" }, [
        _createElementVNode("h3", { class: "fw-bolder m-0" }, "TEST A")
      ])
    ], -1)),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_apexchart, {
          width: "380",
          type: "donut",
          options: _ctx.options,
          series: _ctx.series
        }, null, 8, ["options", "series"])
      ])
    ])
  ]))
}