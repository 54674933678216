export const userGroupSk = {
  userGroups: {
    toolTips: {
      edit: "Upraviť skupinu {group}",
      delete: "Odstrániť skupinu {group}",
      default: "Predvolená skupina",
    },
    unassigned: "Nezaradení",
    editTitle: "Upraviť skupinu",
    newTitle: "Nová skupina",
    addGroup: "Pridať skupinu",
    errors: {
      notFound: "Skupina s ID {id} nebola nájdená",
      notYourRole: "Nemáte právo zobraziť skupinu s ID {id}",
      nameExists: "Skupina s názvom `{name}` už existuje",
      commonGET_USER_GROUP_BY_ID: "Chyba pri získavaní údajov o skupine",
      commonDELETE_USER_GROUP_BY_ID: "Chyba pri odstraňovaní skupiny",
      commonDELETE_USER_GROUPS: "Nepodarilo sa odstrániť skupiny",
      cantGetUserGroups: "Chyba pri získavaní údajov o skupinách",
      defaultExists: "Predvolená skupina pre túto firmu už existuje",
    },
    form: {
      name: "Názov",
      company: "Firma",
      oragnization: "Organizácia",
      diagnostics: "Diagnostiky",
      default: "Predvolená",
      forceDiagToView: "Spravovat pouze vybrané diagnostiky",
      parentUserGroup: "Nadradená skupina",
      userGroupChanged: "Skupina bola upravená",
      userGroupChangedFail: "Nepodarilo sa upraviť skupinu",
      userGroupAdded: "Nová skupina bola vytvorená",
      userGroupAddFail: "Nepodarilo sa vytvoriť novú skupinu",
    },
    table: {
      header: {
        name: "Názov",
        company: "Firma",
        oragnization: "Organizácia",
        diagnostics: "Diagnostiky",
        default: "Predvolená",
        forceDiagToView: "Spravovat pouze vybrané diagnostiky",
        parentUserGroup: "Nadradená skupina",
        actions: "Akcie",
      },
      deleteUserGroupQuestion:
        "Naozaj chcete odstrániť skupinu <strong>{userGroup}</strong>?",
      searchUserGroups: "Hľadať skupiny",
      userGroupDeleted: "Skupina {userGroup} bola odstránená",
      userGroupsDeleted: "Skupiny boli odstránené",
      noRecords: "Neboli nájdené žiadne skupiny",
    },
  },
};
