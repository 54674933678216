import React, { useRef } from "react";
import { useRememberTheGridMultipleGame } from "./useRememberTheGridMultipleGame";
import presets from "./presets";

const RememberTheGridMultipleGame = ({ onGameEnd, settings }) => {
  const gameSettings = { ...settings, ...presets[settings.level - 1] };
  const gridRef = useRef(null);
  const { gridComponent, showStartButton, startGame } =
    useRememberTheGridMultipleGame(gameSettings, onGameEnd, gridRef);

  return (
    <div className="App">
      <h1>Krátkodobá paměť (Pamatuj si mřížku s více obrazci) </h1>

      {gridComponent}

      {showStartButton && <button onClick={startGame}>Start Game</button>}
    </div>
  );
};

export default RememberTheGridMultipleGame;
