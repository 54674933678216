import { createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-6" }
const _hoisted_2 = { class: "col-lg-8 fv-row" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "col-11" }
const _hoisted_6 = { class: "col-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_slider = _resolveComponent("el-slider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("label", {
        class: _normalizeClass(["col-lg-4 col-form-label fw-bold fs-6", _ctx.isRunning ? 'text-secondary' : ''])
      }, _cache[4] || (_cache[4] = [
        _createElementVNode("span", { class: "text-primary" }, "BPM", -1)
      ]), 2),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          disabled: _ctx.isRunning,
          type: "text",
          class: _normalizeClass(["form-control form-control-lg form-control-solid", _ctx.isRunning ? 'text-secondary' : '']),
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.beatsPerMinuteRef) = $event))
        }, null, 10, _hoisted_3), [
          [_vModelText, _ctx.beatsPerMinuteRef]
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", {
          style: {"border-radius":"100px"},
          class: _normalizeClass(["p-10", _ctx.tick || !_ctx.isRunning ? '' : 'bg-light-info'])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["btn btn-lg position-relative m-auto game-start-button top-0 mt-5", _ctx.tick || !_ctx.isRunning ? 'btn-primary' : 'btn-danger']),
            onMousedown: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.runOrStop && _ctx.runOrStop(...args))),
            onKeydown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.runOrStop && _ctx.runOrStop(...args)))
          }, [
            _createElementVNode("i", {
              class: _normalizeClass(["bi", _ctx.isRunning ? 'bi bi-pause' : 'bi bi-play'])
            }, null, 2)
          ], 34)
        ], 2)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_el_slider, {
          vertical: "",
          height: "100px",
          modelValue: _ctx.volumeRef,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.volumeRef) = $event)),
          "show-tooltip": false,
          min: 0,
          max: 2,
          step: 0.05
        }, null, 8, ["modelValue"])
      ])
    ])
  ], 64))
}