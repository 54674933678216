import React, { useState, useEffect, useRef } from "react";
import Grid from "../../Components/Grid/Grid";
import scenarios from "./scenarios";

// const getRandomSvg = () => {
//   const svgs = [Ellipse, Rectangle, Triangle];
//   return svgs[Math.floor(Math.random() * svgs.length)];
// };

// const getRandomSvgAndColor = (excludeShape) => {
//   let SvgComponent;
//   do {
//     SvgComponent = getRandomSvg();
//   } while (SvgComponent === excludeShape);
//
//   const color = Color.getRandomColor();
//   return { SvgComponent, color };
// };

export const usePickUniqueObjectGame = (settings, onGameEnd) => {
  const { rows, cols, amountOfCharacters } = settings;

  const initialGridContent = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => <div />)
  );

  const [gridContent, setGridContent] = useState(initialGridContent);
  const [rightAnswers, setRightAnswers] = useState(0);
  const [wrongAnswers, setWrongAnswers] = useState(0);
  const [timer, setTimer] = useState(60);
  const [isGameStarted, setIsGameStarted] = useState(false);
  const timerIdRef = useRef(null);

  useEffect(() => {
    if (isGameStarted && timer > 0 && timerIdRef.current === null) {
      timerIdRef.current = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    return () => {
      if (timerIdRef.current !== null) {
        clearInterval(timerIdRef.current);
        timerIdRef.current = null;
      }
    };
  }, [timer, isGameStarted]);

  useEffect(() => {
    if (isGameStarted) {
      initializeGame();
    }
  }, [isGameStarted]);

  useEffect(() => {
    if (timer === 0) {
      clearInterval(timerIdRef.current);
      timerIdRef.current = null;
      onGameEnd({
        games: [
          {
            id: "PickUniqueObjectGame",
            right: rightAnswers,
            wrong: wrongAnswers,
          },
        ],
      });
      setIsGameStarted(false);
    }
  }, [timer, rightAnswers, wrongAnswers]);

  const initializeGame = () => {
    if (!isGameStarted) return;

    const gridSize = cols;
    const uniqueIndex = Math.floor(Math.random() * gridSize * gridSize);
    const scenarioIndex = Math.floor(Math.random() * scenarios.length);
    const newGridContent = scenarios[scenarioIndex](
      gridSize,
      uniqueIndex,
      amountOfCharacters
    );

    setGridContent(newGridContent);
  };

  const handleCellClick = (row, col) => {
    const cell = gridContent[row][col];

    if (cell.SvgComponent && isGameStarted) {
      if (cell.isUnique) {
        setRightAnswers((prevRightAnswers) => prevRightAnswers + 1);
      } else {
        setWrongAnswers((prevWrongAnswers) => prevWrongAnswers + 1);
      }

      initializeGame();
    }
  };

  const startGame = () => {
    setTimer(60);
    setRightAnswers(0);
    setWrongAnswers(0);
    setIsGameStarted(true);
  };

  const gridComponent = (
    <Grid
      gridContent={gridContent.map((row) => row.map((cell) => cell.content))}
      onCellClick={handleCellClick}
    />
  );

  return {
    gridComponent,
    rightAnswers,
    timer,
    handleCellClick,
    initializeGame,
    startGame,
    isGameStarted,
  };
};
