export const userGroupCs = {
  userGroups: {
    toolTips: {
      edit: "Upravit skupinu {group}",
      delete: "Odstranit skupinu {group}",
      default: "Výchozí skupina",
    },
    unassigned: "Nezařazeni",
    editTitle: "Upravit skupinu",
    newTitle: "Nová skupina",
    addGroup: "Přidat skupinu",
    errors: {
      notFound: "Skupina id {id} nebyla nalezena",
      notYourRole: "Nemáte práva zobrazit skupinu id {id}",
      nameExists: "Skupina s nazvem `{name}` již existuje",
      commonGET_USER_GROUP_BY_ID: "Chyba při získávání dat o skupině",
      commonDELETE_USER_GROUP_BY_ID: "Chyba při odstraňování skupiny",
      commonDELETE_USER_GROUPS: "Nepodařilo se odstranit skupiny",
      cantGetUserGroups: "Chyba při získávání dat o skupinách",
      defaultExists: "Výchozí skupina pro tuto firmu již existuje",
    },
    form: {
      name: "Název",
      company: "Firma",
      oragnization: "Organizace",
      diagnostics: "Diagnostiky",
      default: "Výchozí",
      forceDiagToView: "Spravovat pouze vybrané diagnostiky",
      parentUserGroup: "Nadřazená skupina",
      userGroupChanged: "Skupina byla upravena",
      userGroupChangedFail: "Nepodařilo se upravit skupinu",
      userGroupAdded: "Nová skupina byla vytvořena",
      userGroupAddFail: "Nepodařilo se vytvořit novou skupinu",
    },
    table: {
      header: {
        name: "Název",
        company: "Firma",
        oragnization: "Organizace",
        diagnostics: "Diagnostiky",
        default: "Výchozí",
        forceDiagToView: "Spravovat pouze vybrané diagnostiky",
        parentUserGroup: "Nadřazená skupina",
        actions: "Akce",
      },
      deleteUserGroupQuestion:
        "Opravdu chcete odstranit skupinu <strong>{userGroup}</strong>?",
      searchUserGroups: "Hledat skupiny",
      userGroupDeleted: "Skupina {userGroup} byla odstraněna",
      userGroupsDeleted: "Skupiny byly odstraněny",
      noRecords: "Nebyly nalezeny žádné skupiny",
    },
  },
};
