export const programEn = {
  programs: {
    toolTips: {
      edit: "Edit product {program}",
      delete: "Delete product {program}",
      showEshopProduct: "Show linked eshop product",
    },
    editTitle: "Edit product",
    newTitle: "New product",
    addProgram: "Add product",
    noBasicTrainings: "The group has no established basic programs",
    errors: {
      notFound: "Program {id} not found",
      notYourRole: "Cant access data for product {id}",
      nameExists: "Program named `{name}` already exists",
      commonGET_PROGRAM_BY_ID: "Error retrieving product data",
      commonDELETE_PROGRAM_BY_ID: "Error deleting product",
      commonDELETE_PROGRAMS: "Error deleting products",
      cantGetPrograms: "Failed to fetch products data",
    },
    form: {
      name: "Name",
      type: "Program type",
      basicPrograms: "Basic programs",
      programGroups: "Program group",
      name_client: "Name for client",
      nrOfWeeks: "Duration of program",
      weeks: "weeks",
      wcProduct: "Eshop product",
      company: "Company",
      specializations: "Specializations",
      trainings: "Trainings",
      trainingTemplates: "Training templates",
      programChanged: "Program was stored",
      programChangedFail: "Failed to store product",
      programAdded: "New product was created",
      programAddFail: "Failed to add new product",
    },
    types: {
      grouped: "Grouped",
      basic: "Basic",
    },
    table: {
      header: {
        name: "Name",
        type: "Program type",
        basicPrograms: "Basic programs",
        programGroups: "Program group",
        name_client: "Name for client",
        nrOfWeeks: "Duration of program",
        company: "Company",
        specializations: "Specializations",
        actions: "Actions",
      },

      deleteProgramQuestion:
        "Do you really want to delete product <strong>{program}</strong>?",
      searchPrograms: "Search products",
      programDeleted: "Product {program} was deleted",
      programsDeleted: "Products were deleted",
      noRecords: "No products found",
    },
  },
};
