<template>
  <!--begin::Input group-->
  <div class="row mb-6">
    <!--begin::Label-->
    <label
      class="col-lg-4 col-form-label fw-bold fs-6"
      :class="isRunning ? 'text-secondary' : ''"
    >
      <span class="text-primary">BPM</span>
    </label>
    <!--end::Label-->

    <div class="col-lg-8 fv-row">
      <input
        :disabled="isRunning"
        type="text"
        class="form-control form-control-lg form-control-solid"
        :class="isRunning ? 'text-secondary' : ''"
        v-model="beatsPerMinuteRef"
      />
    </div>
    <!--end::Col-->
  </div>
  <!--end::Input group-->

  <!-- The volume slider -->
  <div class="row">
    <div class="col-11">
      <div
        style="border-radius: 100px"
        class="p-10"
        :class="tick || !isRunning ? '' : 'bg-light-info'"
      >
        <div
          class="btn btn-lg position-relative m-auto game-start-button top-0 mt-5"
          :class="tick || !isRunning ? 'btn-primary' : 'btn-danger'"
          @mousedown="runOrStop"
          @keydown="runOrStop"
        >
          <i class="bi" :class="isRunning ? 'bi bi-pause' : 'bi bi-play'"></i>
        </div>
      </div>
    </div>
    <div class="col-1">
      <el-slider
        vertical
        height="100px"
        v-model="volumeRef"
        :show-tooltip="false"
        :min="0"
        :max="2"
        :step="0.05"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import samples from "./samples.json";
import { decode } from "base64-arraybuffer";

/** A Wake Lock API polyfill, to avoid screen locks while running the metronome */
import NoSleep from "nosleep.js";

let audioContext: AudioContext;
let gainNode: GainNode;

/** The loaded sample data, ready to be used with an AudioBufferSourceNode */
let sampleBuffer: AudioBuffer;

/** The playable buffer source node, that emits the click sound
 * @remarks Defined here, because it is to be used only once at a time, and stopped/recreated as needed
 */
let sampleBufferSourceNode: AudioBufferSourceNode;

let interval;

let noSleep = new NoSleep();

/** This is metronome component that allows setting or tapping in a Beats-Per-Minute speed
 * @remarks It allows to tap along the rythm for some beats to adjust the speed properly to the sound
 * @devdoc To adjust, a tap sequence is started with a first tap (after a while), then counting taps as long as the user keeps tapping in a reasonable speed
 */
export default defineComponent({
  components: {},
  props: {
    /** The minimum BPM value accepted
     * @remarks Slower taps will not get recognised as a sequence
     * @remarks The BPM slider will use this as lower end of the range
     */
    bpmMin: {
      type: Number,
      default: 40,
      required: false,
    },
    /** The maximum BPM value accepted
     * @remarks The BPM slider will use this as upper end of the range
     */
    bpmMax: {
      type: Number,
      default: 180,
      required: false,
    },
    beatsPerMinute: {
      type: Number,
      default: 60,
      required: false,
    },
    volume: {
      type: Number,
      default: 60,
      required: false,
    },
  },
  data() {
    return {
      volumeRef: ref(this.volume),
      sequnceTapCount: 0,
      click: false,
      firstTapOfSequenceTimeStamp: 0,
      lastTapTimeStamp: 0,
      beatsPerMinuteRef: ref(this.beatsPerMinute),
      isRunning: false,
      tick: false,
    };
  },
  watch: {
    /** Watches for changes on the volume and immediately applies them
     * @remarks using the change event on the input control does only trigger the function call upon mouse up
     */
    volumeRef(newVal) {
      gainNode.gain.value = newVal;
    },

    /** Watches for changes of the running state and triggers the Wake Lock feature
     * @remarks The screen is prevented from sleep while the metronome is running not disturb the audio and to keep the GUI accessible.
     * When not running sleep is not prevented, to enable default power saving behauviour.
     * @devdoc This currently uses a polyfill for the Wake Lock API (see https://github.com/richtr/NoSleep.js)
     */
    isRunning(newVal) {
      if (newVal === true /*running*/) {
        noSleep.enable();
      }
      if (newVal === false) {
        noSleep.disable();
      }
    },
  },
  methods: {
    /** Updates the BPM rate (period) to the given value
     * @remarks If the metronome was not running, it's started now.
     * @remarks The Audio Context is expected to be ready
     * @param period The period of the beat, in seconds
     */
    updatePeriod(period: number): void {
      if (sampleBufferSourceNode) {
        sampleBufferSourceNode.disconnect();
        clearInterval(interval);
      }
      interval = setInterval(() => {
        setTimeout(() => {
          this.tick = true;
        }, 100);
        this.tick = false;
      }, period * 1000);
      sampleBufferSourceNode = new AudioBufferSourceNode(audioContext);
      sampleBufferSourceNode.buffer = sampleBuffer;
      sampleBufferSourceNode.loop = true;
      sampleBufferSourceNode.loopStart = 0; //Default, start from the beginning
      sampleBufferSourceNode.loopEnd = period;
      sampleBufferSourceNode.connect(gainNode);
      sampleBufferSourceNode.start();
      this.isRunning = true;
    },

    runOrStop(): void {
      if (this.isRunning) {
        this.stop();
      } else {
        this.run();
      }
    },
    /** Handles the run button by (re-)starting the metronome with the current BPM */
    run(): void {
      var period = 60 / this.beatsPerMinuteRef;
      this.tick = true;
      this.updatePeriod(period); //also sets the isRunning state to true
    },

    stop(): void {
      clearInterval(interval);
      if (sampleBufferSourceNode) {
        sampleBufferSourceNode.loop = false;
        this.isRunning = false;
        this.tick = false;
      }
    },
    /** Inizializes the Audio Context
     * @devdoc Initializing the audio context and playing a sample is only allowed after the first user interaction
     */
    initializeAudio(): void {
      audioContext = new AudioContext();

      //Wire the processing chain up
      gainNode = audioContext.createGain();
      gainNode.connect(audioContext.destination);

      //Prepare the sound source
      let byteArray = decode(samples[0].buffer);

      audioContext.decodeAudioData(byteArray, function (buffer) {
        //This decoded buffer will be later used to actually play the sample, using the sampleBufferSourceNode, which is created only when actual playing is needed
        sampleBuffer = buffer;
      });
    },
  },
  mounted() {
    this.initializeAudio();
  },
  unmounted() {
    if (audioContext && audioContext.state !== "closed") {
      audioContext.close().then(function () {
        console.log("AudioContext closed");
      });
    }
  },
});
</script>
