import { useI18n } from "vue-i18n";
import { Resource, ResourceObject } from "@/store/interfaces/Common";

/**
 * A custom hook for translation functionality.
 * @param root An optional root string to prefix all translations with.
 * @returns An object containing translation functions.
 */
export function useTranslate(root?: string) {
  const { t, te, locale } = useI18n();
  const translate = (
    text: string,
    rootOR?: string | undefined,
    params = {}
  ): string => {
    text =
      (rootOR !== undefined ? rootOR + "." : root ? root + "." : "") + text;
    text = text.replace("..", ".");
    if (text) {
      if (te(text)) {
        return t(text, params);
      } else {
        return text;
      }
    } else {
      return "";
    }
  };

  /**
   * Retrieves the appropriate resource object based on the current locale.
   * @param object The resource object containing multiple resources.
   * @returns The resource object corresponding to the current locale, or the first resource if not found.
   */
  const getObjectResourceByLang = (object: ResourceObject): Resource => {
    if (object && object.resources) {
      for (const resource of object.resources) {
        if (resource.language?.code == locale.value) {
          return resource;
        }
      }
      return object.resources[0];
    }
    return {} as Resource;
  };

  /**
   * Retrieves the value of a specified property from the appropriate resource object based on the current locale.
   * @param object The resource object containing multiple resources.
   * @param value The property name whose value needs to be retrieved from the resource object.
   * @returns The value of the specified property from the resource object corresponding to the current locale, or an empty string if not found.
   */
  const getObjectResourceValueByLang = (
    object: ResourceObject,
    value: string
  ): string => {
    const resource = getObjectResourceByLang(object);
    if (value == "client_name" && !resource.client_name) {
      value = "name";
    }
    return resource[value] ?? "";
  };

  return {
    translate,
    t,
    te,
    getObjectResourceByLang,
    getObjectResourceValueByLang,
  };
}
