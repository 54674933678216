import React from "react";
import { usePickUniqueObjectGame } from "./usePickUniqueObjectGame";

const PickUniqueObjectGame = ({ onGameEnd, settings }) => {
  const { gridComponent, timer, startGame, isGameStarted } =
    usePickUniqueObjectGame(settings, onGameEnd);

  return (
    <div>
      <h1>Unikátní objekt</h1>
      {gridComponent}
      {!isGameStarted && <button onClick={startGame}>Start Game</button>}
      {isGameStarted && <h2>Zbývá čas: {timer}</h2>}
    </div>
  );
};

export default PickUniqueObjectGame;
